var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_vm._m(0),(_vm.listasForms.caracteristicas.length > 0)?_c('tbody',_vm._l((_vm.listasForms.caracteristicas),function(caracteristica){return _c('tr',{key:caracteristica.id,staticClass:"fila"},[_c('td',[_vm._v(_vm._s(caracteristica.nombre_campo))]),_c('td',[(caracteristica.tipo_campo === 1)?_c('input',{staticClass:"form-control form-control-sm",class:caracteristica.obligatorio ? 'is-invalid' : '',attrs:{"type":"number","placeholder":caracteristica.nTipoCampo,"id":'campo_' + caracteristica.id,"data-vehiculo_id":_vm.vehiculo_id,"data-caracteristica_id":caracteristica.id,"data-nombre_campo":caracteristica.nombre_campo},domProps:{"value":caracteristica.caracteristica
                ? caracteristica.caracteristica.valor
                : ''},on:{"keyup":function($event){caracteristica.obligatorio ? _vm.validaCampo(caracteristica.id) : ''}}}):(caracteristica.tipo_campo === 2)?_c('input',{staticClass:"form-control form-control-sm",class:caracteristica.obligatorio ? 'is-invalid' : '',attrs:{"type":"text","maxlength":caracteristica.tamano_campo,"placeholder":caracteristica.nTipoCampo,"data-vehiculo_id":_vm.vehiculo_id,"data-caracteristica_id":caracteristica.id,"data-nombre_campo":caracteristica.nombre_campo},domProps:{"value":caracteristica.caracteristica
                ? caracteristica.caracteristica.valor
                : ''},on:{"keyup":function($event){caracteristica.obligatorio ? _vm.validaCampo(caracteristica.id) : ''}}}):(caracteristica.tipo_campo === 3)?_c('div',{staticClass:"be-check"},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"checkbox","data-vehiculo_id":_vm.vehiculo_id,"data-caracteristica_id":caracteristica.id,"data-nombre_campo":caracteristica.nombre_campo},domProps:{"checked":caracteristica.caracteristica &&
                caracteristica.caracteristica.valor == 'true'
                  ? 'checked'
                  : ''}})]):(caracteristica.tipo_campo === 4)?_c('input',{staticClass:"form-control form-control-sm",class:caracteristica.obligatorio ? 'is-invalid' : '',attrs:{"type":"email","placeholder":caracteristica.nTipoCampo,"data-vehiculo_id":_vm.vehiculo_id,"data-caracteristica_id":caracteristica.id,"data-nombre_campo":caracteristica.nombre_campo},domProps:{"value":caracteristica.caracteristica
                ? caracteristica.caracteristica.valor
                : ''},on:{"keyup":function($event){caracteristica.obligatorio ? _vm.validaCampo(caracteristica.id) : ''}}}):(caracteristica.tipo_campo === 5)?_c('input',{staticClass:"form-control form-control-sm",class:caracteristica.obligatorio ? 'is-invalid' : '',attrs:{"type":"date","placeholder":caracteristica.nTipoCampo,"data-vehiculo_id":_vm.vehiculo_id,"data-caracteristica_id":caracteristica.id,"data-nombre_campo":caracteristica.nombre_campo},domProps:{"value":caracteristica.caracteristica
                ? caracteristica.caracteristica.valor
                : ''},on:{"keyup":function($event){caracteristica.obligatorio ? _vm.validaCampo(caracteristica.id) : ''}}}):(caracteristica.tipo_campo === 6)?_c('input',{staticClass:"form-control form-control-sm",class:caracteristica.obligatorio ? 'is-invalid' : '',attrs:{"type":"tel","placeholder":caracteristica.nTipoCampo,"data-vehiculo_id":_vm.vehiculo_id,"data-caracteristica_id":caracteristica.id,"data-nombre_campo":caracteristica.nombre_campo},domProps:{"value":caracteristica.caracteristica
                ? caracteristica.caracteristica.valor
                : ''},on:{"keyup":function($event){caracteristica.obligatorio ? _vm.validaCampo(caracteristica.id) : ''}}}):_c('div',{staticClass:"text-danger"},[_vm._v("Tipo de campo no especificado")])]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:[
              caracteristica.obligatorio == 1
                ? 'badge-danger'
                : 'badge-success',
            ]},[_vm._v(" "+_vm._s(caracteristica.obligatorio == 1 ? "SI" : "NO")+" ")])]),_c('td',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button","id":`btn_${caracteristica.id}`},on:{"click":function($event){return _vm.save(`btn_${caracteristica.id}`)}}},[_c('i',{staticClass:"fas fa-edit"})])])])])}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Característica")]),_c('th',[_vm._v("Campo")]),_c('th',{staticStyle:{"width":"50px"}},[_vm._v("Obligatorio")]),_c('th',{staticStyle:{"width":"50px"}},[_vm._v("Acción")])])])
}]

export { render, staticRenderFns }