<template>
  <div>
    <table class="table table-bordered table-striped table-hover table-sm">
      <thead>
        <tr>
          <th>Característica</th>
          <th>Campo</th>
          <th style="width: 50px">Obligatorio</th>
          <th style="width: 50px">Acción</th>
        </tr>
      </thead>
      <tbody v-if="listasForms.caracteristicas.length > 0">
        <tr
          class="fila"
          v-for="caracteristica in listasForms.caracteristicas"
          :key="caracteristica.id"
        >
          <td>{{ caracteristica.nombre_campo }}</td>
          <td>
            <!-- Es Numerico -->
            <input
              v-if="caracteristica.tipo_campo === 1"
              type="number"
              class="form-control form-control-sm"
              :placeholder="caracteristica.nTipoCampo"
              :id="'campo_' + caracteristica.id"
              :class="caracteristica.obligatorio ? 'is-invalid' : ''"
              :value="
                caracteristica.caracteristica
                  ? caracteristica.caracteristica.valor
                  : ''
              "
              :data-vehiculo_id="vehiculo_id"
              :data-caracteristica_id="caracteristica.id"
              :data-nombre_campo="caracteristica.nombre_campo"
              @keyup="
                caracteristica.obligatorio ? validaCampo(caracteristica.id) : ''
              "
            />
            <!-- Es Texto -->
            <input
              v-else-if="caracteristica.tipo_campo === 2"
              type="text"
              class="form-control form-control-sm"
              v-bind:maxlength="caracteristica.tamano_campo"
              :placeholder="caracteristica.nTipoCampo"
              :class="caracteristica.obligatorio ? 'is-invalid' : ''"
              :value="
                caracteristica.caracteristica
                  ? caracteristica.caracteristica.valor
                  : ''
              "
              :data-vehiculo_id="vehiculo_id"
              :data-caracteristica_id="caracteristica.id"
              :data-nombre_campo="caracteristica.nombre_campo"
              @keyup="
                caracteristica.obligatorio ? validaCampo(caracteristica.id) : ''
              "
            />
            <!-- Es Checkbox -->
            <div v-else-if="caracteristica.tipo_campo === 3" class="be-check">
              <input
                type="checkbox"
                class="form-control form-control-sm"
                :checked="
                  caracteristica.caracteristica &&
                  caracteristica.caracteristica.valor == 'true'
                    ? 'checked'
                    : ''
                "
                :data-vehiculo_id="vehiculo_id"
                :data-caracteristica_id="caracteristica.id"
                :data-nombre_campo="caracteristica.nombre_campo"
              />
            </div>
            <!-- Es E-mail -->
            <input
              v-else-if="caracteristica.tipo_campo === 4"
              type="email"
              class="form-control form-control-sm"
              :placeholder="caracteristica.nTipoCampo"
              :class="caracteristica.obligatorio ? 'is-invalid' : ''"
              :value="
                caracteristica.caracteristica
                  ? caracteristica.caracteristica.valor
                  : ''
              "
              :data-vehiculo_id="vehiculo_id"
              :data-caracteristica_id="caracteristica.id"
              :data-nombre_campo="caracteristica.nombre_campo"
              @keyup="
                caracteristica.obligatorio ? validaCampo(caracteristica.id) : ''
              "
            />
            <!-- Es Fecha -->
            <input
              v-else-if="caracteristica.tipo_campo === 5"
              type="date"
              class="form-control form-control-sm"
              :placeholder="caracteristica.nTipoCampo"
              :class="caracteristica.obligatorio ? 'is-invalid' : ''"
              :value="
                caracteristica.caracteristica
                  ? caracteristica.caracteristica.valor
                  : ''
              "
              :data-vehiculo_id="vehiculo_id"
              :data-caracteristica_id="caracteristica.id"
              :data-nombre_campo="caracteristica.nombre_campo"
              @keyup="
                caracteristica.obligatorio ? validaCampo(caracteristica.id) : ''
              "
            />
            <!-- Es Teléfono -->
            <input
              v-else-if="caracteristica.tipo_campo === 6"
              type="tel"
              class="form-control form-control-sm"
              :placeholder="caracteristica.nTipoCampo"
              :class="caracteristica.obligatorio ? 'is-invalid' : ''"
              :value="
                caracteristica.caracteristica
                  ? caracteristica.caracteristica.valor
                  : ''
              "
              :data-vehiculo_id="vehiculo_id"
              :data-caracteristica_id="caracteristica.id"
              :data-nombre_campo="caracteristica.nombre_campo"
              @keyup="
                caracteristica.obligatorio ? validaCampo(caracteristica.id) : ''
              "
            />
            <!-- No es nunguno -->
            <div v-else class="text-danger">Tipo de campo no especificado</div>
          </td>
          <td class="text-center">
            <span
              class="badge"
              v-bind:class="[
                caracteristica.obligatorio == 1
                  ? 'badge-danger'
                  : 'badge-success',
              ]"
            >
              {{ caracteristica.obligatorio == 1 ? "SI" : "NO" }}
            </span>
          </td>
          <td>
            <div class="btn-group float-right">
              <button
                type="button"
                class="btn btn-sm bg-navy"
                :id="`btn_${caracteristica.id}`"
                @click="save(`btn_${caracteristica.id}`)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "VehiculoCaracteristica",
  data() {
    return {
      listasForms: {
        caracteristicas: [],
      },
      form: {},
      tipo_vehiculo_id: null,
      vehiculo_id: null,
    };
  },
  methods: {
    getIndex() {
      this.tipo_vehiculo_id = this.$parent.form.tipo_vehiculo_id;
      this.vehiculo_id = this.$parent.id;
      axios
        .get(
          `/api/admin/caracteristicasVehiculo/lista/${this.tipo_vehiculo_id}`
        )
        .then((response) => {
          this.listasForms.caracteristicas = response.data;
          this.getCaracteristicas();
        });
    },

    getCaracteristicas() {
      this.listasForms.caracteristicas.forEach((element) => {
        this.$parent.form.caracteristicas.forEach((element1) => {
          if (element.id == element1.id) {
            this.$set(element, "caracteristica", element1.pivot);
          }
        });
      });
    },

    // Función de validación de contenido para los campos number, text, e-mail, date, telefono
    validaCampo(caracteristica_id) {
      const campo = $(`#campo_${caracteristica_id}`);
      campo.removeClass();
      if (campo.val()) {
        campo.addClass("form-control form-control-sm is-valid");
      } else {
        campo.addClass("form-control form-control-sm is-invalid");
      }
    },

    save(btn) {
      const data = $(`#${btn}`).parents(`.fila`).find(`.form-control`);
      if (data.attr("type") == "checkbox") {
        data.val("false");
        if (data.prop("checked") == true) {
          data.val("true");
        }
      }

      if (data.val()) {
        this.form = {
          vehiculo_id: data.data("vehiculo_id"),
          caracteristica_id: data.data("caracteristica_id"),
          valor: data.val(),
        };

        axios({
          method: "PUT",
          url: `/api/admin/vehiculos/assignOrRevokeCaracteristicasVehiculos`,
          data: this.form,
        })
          .then((result) => {
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: `No se ha diligenciado el campo ${data.data("nombre_campo")}`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
