<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Vehículos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Vehículos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Vehículos</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Documentos"
                    data-toggle="tab"
                    href="#Documentos"
                    v-if="form.id != null"
                    >Documentos</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Caracteristicas"
                    data-toggle="tab"
                    href="#Caracteristicas"
                    v-if="form.id != null"
                    >Características</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="empresa">Empresa</label>
                              <v-select
                                :class="[
                                  $v.form.empresa_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                :disabled="opcion == 1 || opcion == 3"
                                v-model="empresa"
                                placeholder="Empresa"
                                label="razon_social"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.empresas"
                                @input="selectEmpresa()"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.empresa_id.required"
                              >
                                Seleccione una empresa
                              </div>
                            </div>
                          </div>
                          <!--                           <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_capacidad"
                                >Tipo de Capacidad</label
                              >
                              <select
                                class="form-control form-control-sm"
                                id="tipo_capacidad"
                                :disabled="opcion == 1 || opcion == 3"
                                v-model="form.tipo_capacidad"
                                :class="
                                  $v.form.tipo_capacidad.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_capacidad in listasForms.tipos_capacidad"
                                  :key="tipo_capacidad.numeracion"
                                  :value="tipo_capacidad.numeracion"
                                >
                                  {{ tipo_capacidad.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_capacidad.required"
                              >
                                Seleccione un tipo de capacidad
                              </div>
                            </div>
                          </div> -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="peso_vacio">Peso Vacio</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.peso_vacio"
                                :class="
                                  $v.form.peso_vacio.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="placa">Placa</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                id="placa"
                                maxlength="6"
                                v-model="form.placa"
                                :class="
                                  $v.form.placa.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaVehiculo()"
                              />
                              <div class="error" v-if="!$v.form.placa.required">
                                Diligencie una placa
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_combustible">Combustible</label>
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                id="tipo_combustible"
                                v-model="form.tipo_combustible"
                                :class="
                                  $v.form.tipo_combustible.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @change="CargarLineas()"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="combustible in listasForms.combustibles"
                                  :key="combustible.numeracion"
                                  :value="combustible.numeracion"
                                >
                                  {{ combustible.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_combustible.required"
                              >
                                Seleccione una combustible
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="marca">Marca</label>
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                id="marca"
                                v-model="form.marca"
                                :class="
                                  $v.form.marca.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @change="CargarLineas()"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="marca in listasForms.marcas"
                                  :key="marca.numeracion"
                                  :value="marca.numeracion"
                                >
                                  {{ marca.descripcion }}
                                </option>
                              </select>
                              <div class="error" v-if="!$v.form.marca.required">
                                Seleccione una marca
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3"
                            v-if="
                              form.marca != null &&
                                form.tipo_combustible != null
                            "
                          >
                            <div class="form-group">
                              <label for="linea_vehicular">Linea</label>
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                id="linea_vehicular"
                                v-model="form.linea_vehicular_id"
                                :class="
                                  form.tipo_combustible == 1
                                    ? $v.form.linea_vehicular_id.$invalid
                                      ? 'is-invalid'
                                      : 'is-valid'
                                    : ''
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="linea_vehicular in listasForms.lineas_vehiculares"
                                  :key="linea_vehicular.id"
                                  :value="linea_vehicular.id"
                                >
                                  {{ linea_vehicular.linea }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Tipo de Vehículo</label>
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                v-model="form.tipo_vehiculo_id"
                                :class="
                                  $v.form.tipo_vehiculo_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @change="
                                  form.id
                                    ? $refs.VehiculoCaracteristica.getIndex()
                                    : ''
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_vehiculo in listasForms.tipos_vehiculo"
                                  :key="tipo_vehiculo.id"
                                  :value="tipo_vehiculo.id"
                                >
                                  {{ tipo_vehiculo.nombre }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_vehiculo_id.required"
                              >
                                Seleccione un tipo de vehículo
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_carroceria"
                                >Tipo de Carrocería</label
                              >
                              <select
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="tipo_carroceria"
                                v-model="form.tipo_carroceria"
                                :class="
                                  $v.form.tipo_carroceria.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_carroceria in listasForms.tipos_carroceria"
                                  :key="tipo_carroceria.numeracion"
                                  :value="tipo_carroceria.numeracion"
                                >
                                  {{ tipo_carroceria.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_carroceria.required"
                              >
                                Seleccione una tipo de carrocería
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_propiedad">Propiedad</label>
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                id="tipo_propiedad"
                                v-model="form.tipo_propiedad"
                                :class="
                                  $v.form.tipo_propiedad.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="propiedad in listasForms.propiedades"
                                  :key="propiedad.numeracion"
                                  :value="propiedad.numeracion"
                                >
                                  {{ propiedad.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_propiedad.required"
                              >
                                Seleccione una propiedad
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Tipo Servicio Placa</label>
                              <select
                                class="form-control form-control-sm"
                                v-model="form.tipo_servicio_placa"
                                :class="
                                  $v.form.tipo_servicio_placa.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="opcion == 1 || opcion == 3"
                                placeholder="Seleccione"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  :value="opt.numeracion"
                                  v-for="opt in listasForms.tipo_servicio_placa"
                                  :key="opt.id"
                                  >{{ opt.descripcion }}</option
                                >
                              </select>
                            </div>
                            <div
                              class="error"
                              v-if="!$v.form.tipo_servicio_placa.required"
                            >
                              Seleccione una propiedad
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="modelo">Modelo</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="modelo"
                                min="1970"
                                max="2100"
                                v-model="form.modelo"
                                :class="
                                  $v.form.modelo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.modelo.required"
                              >
                                Diligencie un modelo
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="modelo_re_potenciado"
                                >Modelo Repotenciado</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="modelo_re_potenciado"
                                min="1970"
                                max="2100"
                                v-model="form.modelo_re_potenciado"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="color">Color</label>
                              <select
                                :disabled="opcion == 1"
                                class="form-control form-control-sm"
                                id="color"
                                v-model="form.color"
                                :class="
                                  $v.form.color.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="color in listasForms.colores"
                                  :key="color.numeracion"
                                  :value="color.numeracion"
                                >
                                  {{ color.descripcion }}
                                </option>
                              </select>
                              <div class="error" v-if="!$v.form.color.required">
                                Seleccione un color
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tarjeta_propiedad"
                                >Tarjeta de Propiedad</label
                              >
                              <input
                                :disabled="opcion == 1"
                                type="text"
                                class="form-control form-control-sm"
                                id="tarjeta_propiedad"
                                v-model="form.tarjeta_propiedad"
                                :class="
                                  $v.form.tarjeta_propiedad.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.tarjeta_propiedad.required"
                              >
                                Diligencie una tarjeta de propiedad
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="numero_motor">Número de Motor</label>
                              <input
                                :disabled="opcion == 1"
                                type="text"
                                class="form-control form-control-sm"
                                id="numero_motor"
                                v-model="form.numero_motor"
                                :class="
                                  $v.form.numero_motor.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.numero_motor.required"
                              >
                                Diligencie un número de motor
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="numero_chasis"
                                >Número de Chasis</label
                              >
                              <input
                                :disabled="opcion == 1"
                                type="text"
                                class="form-control form-control-sm"
                                id="numero_chasis"
                                v-model="form.numero_chasis"
                                :class="
                                  $v.form.numero_chasis.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.numero_chasis.required"
                              >
                                Diligencie un número de chasis
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="odometro">Odómetro</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                id="odometro"
                                v-model="form.odometro"
                                :class="
                                  $v.form.odometro.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.odometro.required"
                              >
                                Diligencie Odómetro
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3"
                            v-if="
                              $store.getters.can(
                                'admin.vehiculos.proveedorSatelital'
                              )
                            "
                          >
                            <div class="form-group">
                              <label for="proveedor">Proveedor</label>
                              <select
                                class="form-control form-control-sm"
                                id="proveedor"
                                v-model="form.proveedor_id"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="proveedor in listasForms.proveedores"
                                  :key="proveedor.id"
                                  :value="proveedor.id"
                                >
                                  {{ proveedor.nombre }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Comunidad</label>
                              <input
                                type="checkbox"
                                class="form-control form-control-sm"
                                v-model="form.comunidad"
                              />
                            </div>
                          </div>
                          <div
                            class="col-md-4"
                            v-if="form.tipo_combustible == 1"
                          >
                            <div class="form-group">
                              <label for="">Original/Convertido</label>
                              <select
                                class="form-control form-control-sm p-0"
                                v-model="form.original"
                                :class="
                                  $v.form.original.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option :value="null">Seleccione..</option>
                                <option :value="1">Original</option>
                                <option :value="2">Convertido</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="form.id">
                        <label for="imagen">Foto Vehículo</label>
                        <div
                          class="form-group"
                          v-if="form.link_fotografia == null"
                        >
                          <input
                            type="file"
                            class="form-control-file"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="obtenerImagen"
                          />
                        </div>
                        <figure class="form-group" v-if="miniLogo">
                          <div class="button-container">
                            <img
                              :src="miniLogo"
                              alt="Logo"
                              width="236"
                              height="125"
                              class="rounded mx-auto d-block"
                            />
                            <a
                              @click="destroyImage()"
                              id="BotonEliminar"
                              v-if="form.link_fotografia"
                              href="#"
                              :disabled="opcion == 1"
                              >Eliminar <i class="fas fa-window-close"></i
                            ></a>
                          </div>
                        </figure>
                        <div class="row">
                          <div class="col-md-9"></div>
                          <div class="col-md-3" v-if="archivoValido">
                            <button
                              type="button"
                              @click="saveImage()"
                              class="btn btn-success"
                              data-toggle="tooltip"
                              data-html="true"
                              title="Guardar Imagen"
                            >
                              <i class="fa fa-upload"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8 offset-md-2">
                        <VehiculoLinea
                          v-if="
                            form.id != null &&
                              $store.getters.can(
                                'admin.vehiculos.assingOrRevokeLinea'
                              )
                          "
                          ref="VehiculoLinea"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Documentos" v-if="form.id">
                  <VehiculoDocumento
                    v-if="form.id != null"
                    ref="VehiculoDocumento"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  id="Caracteristicas"
                  v-if="form.id != null"
                >
                  <div class="card-body">
                    <VehiculoCaracteristica
                      ref="VehiculoCaracteristica"
                      v-if="form.id != null"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    :disabled="opcion == 1"
                    @click="save()"
                    v-if="
                      $store.getters.can('admin.vehiculos.create') ||
                        $store.getters.can('admin.vehiculos.edit') ||
                        $store.getters.can(
                          'admin.vehiculos.editarTransportadora'
                        )
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import VehiculoCaracteristica from "./VehiculoCaracteristica";
import VehiculoDocumento from "./VehiculoDocumento";
import VehiculoLinea from "./VehiculoLinea";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "VehiculoForm",
  components: {
    VehiculoCaracteristica,
    VehiculoDocumento,
    VehiculoLinea,
    Loading,
    vSelect,
  },
  data() {
    return {
      opcion: 1,
      cargando: false,
      form: {
        empresa_id: null,
        placa: null,
        peso_vacio: null,
        marca: null,
        tipo_vehiculo_id: null,
        tipo_carroceria: null,
        linea_vehicular_id: null,
        modelo: null,
        color: null,
        tarjeta_propiedad: null,
        numero_motor: null,
        numero_chasis: null,
        odometro: null,
        tipo_propiedad: null,
        tipo_combustible: null,
        original: null,
        tipo_servicio_placa: null,
      },
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      metodo: "",
      empresa: [],
      listasForms: {
        empresas: [],
        proveedores: [],
        /*    tipos_capacidad: [], */
        marcas: [],
        lineas_vehiculares: [],
        tipos_vehiculo: [],
        tipos_carroceria: [],
        colores: [],
        estados: [],
        documentos: [],
        propiedades: [],
        combustibles: [],
        tipo_servicio_placa: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      odometroInicial: {},
      id: this.$route.params.id,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    if (this.form.tipo_combustible == 1) {
      return {
        form: {
          empresa_id: {
            required,
          },
          placa: {
            required,
            maxLength: maxLength(6),
            minLength: minLength(6),
          },
          peso_vacio: {
            required,
          },
          marca: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          tipo_carroceria: {
            required,
          },
          linea_vehicular_id: {
            required,
          },
          modelo: {
            required,
            maxLength: maxLength(4),
            minLength: minLength(4),
          },
          color: {
            required,
          },
          tarjeta_propiedad: {
            required,
          },
          numero_motor: {
            required,
          },
          numero_chasis: {
            required,
          },
          odometro: {
            required,
          },
          tipo_propiedad: {
            required,
          },
          tipo_combustible: {
            required,
          },
          original: {
            required,
          },
          tipo_servicio_placa: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          empresa_id: {
            required,
          },
          placa: {
            required,
            maxLength: maxLength(6),
            minLength: minLength(6),
          },
          peso_vacio: {
            required,
          },
          marca: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          tipo_carroceria: {
            required,
          },
          modelo: {
            required,
            maxLength: maxLength(4),
            minLength: minLength(4),
          },
          color: {
            required,
          },
          tarjeta_propiedad: {
            required,
          },
          numero_motor: {
            required,
          },
          numero_chasis: {
            required,
          },
          odometro: {
            required,
          },
          tipo_propiedad: {
            required,
          },
          tipo_combustible: {
            required,
          },
          tipo_servicio_placa: {
            required,
          },
        },
      };
    }
  },
  methods: {
    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    CargarLineas() {
      this.getLineasVehiculares();
    },

    async getProveedores() {
      var url = "/api/tif/proveedores/lista";
      axios
        .get(url, {
          params: {
            empresa_id: this.form.empresa_id,
          },
        })
        .then((response) => {
          this.listasForms.proveedores = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    async getTipoServicioPlaca() {
      await axios.get("/api/lista/181").then((response) => {
        this.listasForms.tipo_servicio_placa = response.data;
      });
    },

    async getLineasVehiculares() {
      await axios
        .get("/api/admin/vehiculos/marcasLineas", {
          params: {
            estado: 1,
            tipo_combustible: this.form.tipo_combustible || null,
            marca: this.form.marca,
          },
        })
        .then((response) => {
          this.listasForms.lineas_vehiculares = response.data;
        });
    },

    async getTiposVehiculos() {
      let me = this;
      let c = 0;
      var url = "api/admin/tiposVehiculos/lista";
      var lineas_id = [];
      if (me.$refs.VehiculoLinea) {
        let lineas = await me.$refs.VehiculoLinea.listasForms.lineas.filter(
          (item) => item.estado == true
        );

        for (let i = 0; i < lineas.length; i++) {
          let linea = lineas[i];
          if (linea.id <= 5) {
            lineas_id[c] = linea.id;
            c++;
          }
        }
      }

      axios
        .get(url, {
          params: {
            linea_negocio_id: lineas_id,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculo = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTiposCarrocerias() {
      await axios.get("/api/lista/9").then((response) => {
        this.listasForms.tipos_carroceria = response.data;
      });
    },

    async getColores() {
      await axios.get("/api/lista/11").then((response) => {
        this.listasForms.colores = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getPropiedades() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.propiedades = response.data;
      });
    },

    async getCombustibles() {
      await axios.get("/api/lista/24").then((response) => {
        this.listasForms.combustibles = response.data;
      });
    },

    validaVehiculo() {
      let params = {
        placa: this.form.placa,
      };

      if (this.form.placa != null) {
        axios
          .get("/api/admin/vehiculos/lista", {
            params,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.form.placa = null;
              this.$swal({
                icon: "error",
                title: "El vehiculo digitado ya se encuentra creado...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    // Funciones para carga de imagenes
    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/admin/vehiculos/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/vehiculos/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/vehiculos",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El Vehículo se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    back() {
      let val_linea = false;
      if (this.$route.params.accion == "Editar") {
        for (
          let i = 0;
          i < this.$refs.VehiculoLinea.listasForms.lineas.length;
          i++
        ) {
          const linea = this.$refs.VehiculoLinea.listasForms.lineas[i];
          if (linea.estado) {
            val_linea = true;
          }
        }
      }
      if (val_linea) {
        return this.$router.replace("/Admin/Vehiculos");
      } else {
        this.$swal({
          icon: "error",
          title: "Debe seleccionar al menos una linea de negocio",
        });
      }
    },

    async init() {
      this.accion = this.$route.params.accion;
      this.opcion = this.$route.params.opcion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.miniLogo = this.uri_docs + this.form.link_fotografia;
        this.empresa = {
          id: this.form.empresa_id,
          razon_social: this.form.empresa.razon_social,
        };
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.form.proveedor = 1;
      }
    },
  },
  computed: {
    logo() {
      return this.miniLogo;
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    await this.init();
    await this.getProveedores();
    /*     await this.getTiposCapacidad(); */
    await this.getMarcas();
    await this.getTipoServicioPlaca();
    await this.getTiposVehiculos();
    await this.getColores();
    await this.getTiposCarrocerias();
    await this.getEstados();
    await this.getCombustibles();
    await this.getPropiedades();
    await this.getLineasVehiculares();
    this.cargando = false;
  },
  created() {},
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
